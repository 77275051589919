import React from "react";
import styled from "@emotion/styled";
import TextAnimation from "./animations/TextAnimation";
import HomeResume from "./HomeResume";

const HomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  min-height: 100vh;
`;

const Wellcome = styled.h3`
  font-size: 4rem;
  color: white;
  text-align: center;
  height: 72vh;
  margin: 5rem 0 0 0;
  @media (min-width: 768px) {
    margin: 30rem 0 0 0;
    height: 65vh;
    min-height: 40rem;
  }
`;

const HomeContent = () => {
  return (
    <HomeContainer>
      <Wellcome>
        <strong>Welcome to my Portfolio!</strong> <br /> I'm a <TextAnimation />{" "}
        developer, <br /> based in Freiburg, Germany.
      </Wellcome>
      <HomeResume />
    </HomeContainer>
  );
};

export default HomeContent;
