import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";
import { useInterval } from "react-use";

const animation = keyframes`
    0% {opacity: 0; 
        transform: translateY(-100px) skewY(10deg) skewX(10deg) rotateZ(30deg); 
        filter: blur(10px)}
    25% {opacity: 1; transform: translateY(0) skewY(0deg) skewX(0deg) rotateZ(0deg); 
        filter: blur(0px)}
    75% {opacity: 1; transform: translateY(0) skewY(0deg) skewX(0deg) rotateZ(0deg); 
        filter: blur(0px)}
    100% {opacity: 0; transform: translateY(-100px) skewY(10deg) skewX(10deg) rotateZ(30deg); 
        filter: blur(10px)}
`;

const Wrapper = styled.span`
  display: inline-block;
  span {
    display: inline-block;
    opacity: 0;
    animation-name: ${animation};
    animation-duration: 6s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
    font-weight: 700;
    color: rgba(227, 138, 36, 1);
    background-color: rgba(13, 74, 99, 0.8);
  }
  span:nth-child(1) {
    animation-delay: 0.1s;
    border-radius: 1rem 0 0 1rem;
  }
  span:nth-child(2) {
    animation-delay: 0.3s;
  }
  span:nth-child(3) {
    animation-delay: 0.5s;
  }
  span:nth-child(4) {
    animation-delay: 0.7s;
  }
  span:nth-child(5) {
    animation-delay: 0.9s;
    border-radius: 0 1rem 1rem 0;
  }
`;

const TextAnimation = () => {
  const reactArray = "React".split("");
  const [items, setItems] = useState(reactArray);
  const [count, setCount] = useState(0);
  const [play, setPlay] = useState(false);

  useInterval(
    () => {
      //Animate React
      setItems(reactArray);
      setCount(count + 1);
      // Looping
      if (count === 1) {
        setCount(0);
        setItems(reactArray);
      }
    },
    play ? 6000 : null
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      // Animate Swift after timeout
      setItems(reactArray);
      setPlay(true);
    }, 4000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Wrapper>
      {items.map((item, index) => (
        <span key={index}>{item}</span>
      ))}
    </Wrapper>
  );
};

export default TextAnimation;
