import React from "react";
import { Link } from "gatsby";
import styled from "@emotion/styled";
import Fade from "react-reveal/Fade";

const ResumeContent = styled.div`
  display: flex;
  flex-direction: column;
  height: fit-content;
  max-width: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 1), #0d4a63);
`;

const ResumeCard = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: fit-content;
  background: white;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
  width: 100%;
  h3 {
    color: var(--accent-light);
    font-size: 3.5rem;
    text-align: center;
    font-weight: 700;
  }
  p {
    font-size: 2.2rem;
    width: 80%;
    line-height: 1.5;
    strong {
      color: var(--accent-light);
    }
    @media (min-width: 768px) {
      width: 45%;
      line-height: 1.7;
      font-size: 2.6rem;
    }
  }
`;
const ResumeCardDark = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  min-height: fit-content;
  background: var(--background-theme);
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
  max-width: 100%;

  h3 {
    color: var(--accent-light);
    font-size: 3.5rem;
    font-weight: 700;
    text-align: center;
  }
  p {
    font-size: 2.6rem;
    width: 60%;
    color: white;
    @media (min-width: 768px) {
      width: 45%;
    }
  }
`;

const GoTo = styled(Link)`
  width: 20rem;
  height: 4rem;
  color: white;
  background-color: var(--accent-light);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
`;

const HomeResume = () => {
  return (
    <ResumeContent>
      <Fade left>
        <ResumeCard>
          <h3>Projects</h3>
          <p>
            When I had to decide which way to go, I resolved to learn{" "}
            <strong>React</strong> as a framework for JavaScript. In the
            Projects section you can see some examples of what I have been
            building so far. <br />
            Despite that, I am always willing to learn new technologies.
          </p>
          <GoTo to={"/projects"}>See more</GoTo>
        </ResumeCard>
      </Fade>
      <Fade right>
        <ResumeCardDark>
          <h3>About</h3>
          <p>
            If you want to know more about my personal motivations, you can read
            a short summary of how my life has brought me to this point.
          </p>
          <GoTo to={"/about"}>See more</GoTo>
        </ResumeCardDark>
      </Fade>
      <Fade left>
        <ResumeCard>
          <h3>Contact</h3>
          <p>
            If you want to contact me, I will be happy to answer any questions
            or proposals. In the Contact section you will find the ways to
            communicate with me.
          </p>
          <GoTo to={"/contact"}>See more</GoTo>
        </ResumeCard>
      </Fade>
    </ResumeContent>
  );
};

export default HomeResume;
